import jsPDF from 'jspdf';
import moment from 'moment';
import autoTable from 'jspdf-autotable';
import PDFMerger from 'pdf-merger-js/browser';
import formatStaffName from './formatStaffName';
import { columns as userAbsenceTableCols } from '../components/charts/UserAbsencesTable';

function addHeaderFooter(pdf, title, roomInfo) {
  pdf.setFontSize(10);
  pdf.setTextColor(100);
  const totalPages = pdf.internal.getNumberOfPages();
  const pageWidth = pdf.internal.pageSize.width;
  const pageHeight = pdf.internal.pageSize.height;
  for (let i = 1; i <= totalPages; i++) {
    pdf.setPage(i);
    pdf.text(`Page ${i} of ${totalPages}`, pageWidth / 2, pageHeight - 0.5, {
      align: 'center',
    });
    pdf.text(title, 1, 0.5, { align: 'left' });
    pdf.text(roomInfo, pageWidth - 1, 0.5, { align: 'right' });
  }
}

function replaceVideosWithLink(html) {
  return html.replace(
    /<iframe[^>]*\s+src=["']([^"']+)["'][^>]*><\/iframe>/g,
    '<a href="$1">$1</a>'
  );
}

export async function exportPdf(cover, filename = 'download.pdf') {
  const html2pdf = await import('html2pdf.js').then((module) => module.default);

  const filenameWithExt = filename.endsWith('.pdf')
    ? filename
    : `${filename}.pdf`;

  const opt = {
    margin: [0.75, 1],
    filename: filenameWithExt,
    image: { type: 'jpeg', quality: 0.98 },
    html2canvas: { scale: 2 },
    jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
    pagebreak: { mode: 'avoid-all' },
  };

  const roomCode = cover.tempRoomCode || cover.entry.roomCode;
  const roomInfo = `Room ${roomCode} ${
    cover.tempRoomCode ? `(Changed from ${cover.entry.roomCode})` : ''
  }`;

  html2pdf()
    .from(replaceVideosWithLink(cover.content))
    .set(opt)
    .toPdf()
    .get('pdf')
    .then((pdf) => addHeaderFooter(pdf, filename, roomInfo))
    .save();
}

export async function exportMultiplePdfs(
  requests = [],
  filename = moment().format('DD-MM-YYYY')
) {
  const html2pdf = await import('html2pdf.js').then((module) => module.default);

  const filenameWithExt = filename.endsWith('.pdf')
    ? filename
    : `${filename}.pdf`;

  const opt = {
    margin: [0.75, 1],
    filename: filenameWithExt,
    image: { type: 'jpeg', quality: 0.98 },
    html2canvas: { scale: 2 },
    jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
    pagebreak: { mode: 'avoid-all' },
  };

  const requestsWithContent = requests.filter((request) => request.content);

  if (!requestsWithContent.length) {
    alert('No cover work available to export');
    return;
  }

  const pdfs = [];
  for (const request of requestsWithContent) {
    const coverDate = moment(request.startDateTime).format('DD MMMM YYYY');
    const className = request.entry.class.name;
    const period = request.entry.timetablePeriod
      ? request.entry.timetablePeriod.name.split(':')[1]
      : `${request.entry.startDateTime.toLocaleString()}
    - ${request.entry.endDateTime.toLocaleString()}`;
    const title = `${className} - Period ${period} - ${coverDate}`;
    const roomCode = request.tempRoomCode || request.entry.roomCode;
    const roomInfo = `Room ${roomCode} ${
      request.tempRoomCode ? `(Changed from ${request.entry.roomCode})` : ''
    }`;
    await html2pdf()
      .from(replaceVideosWithLink(request.content))
      .set(opt)
      .toPdf()
      .get('pdf')
      .then((pdf) => addHeaderFooter(pdf, title, roomInfo))
      .outputPdf('arraybuffer')
      .then((result) => pdfs.push(result));
  }

  const merger = new PDFMerger();
  for (const pdf of pdfs) {
    await merger.add(pdf);
  }
  await merger.save(filename);
}

export async function exportCoverSummary(
  data,
  filename = `${moment().format('DD-MM-YYYY')} Daily Cover Summary`
) {
  const html2pdf = await import('html2pdf.js').then((module) => module.default);

  const filenameWithExt = filename.endsWith('.pdf')
    ? filename
    : `${filename}.pdf`;

  const opt = {
    margin: [0.5, 1],
    filename: filenameWithExt,
    image: { type: 'jpeg', quality: 0.98 },
    html2canvas: { scale: 2 },
    jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
    pagebreak: { avoid: ['tr'] },
  };

  const html = `
    <html>
    <style>
      .table1  {border-collapse:collapse;border-spacing:0;width:100%;border-top:1px solid black;border-right:1px solid black;}
      .table1 td{border-left:1px solid black;border-bottom:1px solid black;font-family:Arial, sans-serif;font-size:14px;
        overflow:hidden;padding:5px 5px;word-break:normal;}
      .table1 th{border-left:1px solid black;border-bottom:1px solid black;font-family:Arial, sans-serif;font-size:14px;
        font-weight:normal;overflow:hidden;padding:5px 5px;word-break:normal;}
      .table1 .tg-6ic8{border-color:black;font-weight:bold;text-align:right;vertical-align:top}
      .table1 .tg-0lax{text-align:left;vertical-align:top}
      .table1 .tg-l2oz{font-weight:bold;text-align:right;vertical-align:top}
      .table2  {border-collapse:collapse;border-spacing:0;width:100%;border-top:1px solid black;border-right:1px solid black;}
      .table2 td{border-left:1px solid black;border-bottom:1px solid black;font-family:Arial, sans-serif;font-size:14px;
        overflow:hidden;padding:5px 5px;word-break:normal;}
      .table2 th{border-left:1px solid black;border-bottom:1px solid black;font-family:Arial, sans-serif;font-size:14px;
        font-weight:normal;overflow:hidden;padding:5px 5px;word-break:normal;}
      .table2 .tg-llyw{background-color:#D3D3D3;border-color:black;text-align:left;vertical-align:top}
      .table2 .tg-fymr{border-color:black;font-weight:bold;text-align:left;vertical-align:top}
      .table2 .tg-0pky{border-color:black;text-align:left;vertical-align:top}
      .table2 .tg-v80t{background-color:#D3D3D3;border-color:black;color:#D3D3D3;text-align:left;vertical-align:top}
      tr {page-break-inside:avoid;}
    </style>
    <body>
      <div>
        <h1 style="text-align:center;line-height:0px;">${data.date}</h1>
        <h2 style="text-align:center;">Staff Absence/Off Timetabled Lessons</h2>
        <table class="table1">
          <tbody>
          <tr>
            <td class="tg-6ic8">All Day</td>
            <td class="tg-0lax">
              ${data.absentAllDay.join(', ')}
            </td>
          </tr>
          ${data.periods
            .map(
              (period) => `<tr>
            <td className="tg-l2oz" style="text-align:right;font-weight:bold;">${
              period.name
            }</td>
            <td className="tg-0lax">${period.entries
              .filter((entry) => {
                const [request] = entry.coverRequests;
                const staff = formatStaffName(request.requestedBy);
                return !data.absentAllDay.includes(staff);
              })
              .map((entry) => {
                const [request] = entry.coverRequests;
                return formatStaffName(request.requestedBy);
              })
              .join(', ')}</td>
          </tr>`
            )
            .join('')}
        </tbody>
        </table>
        <h2 style="text-align:center;">Full List of Staff and Room Details</h2>
        <table class="table2">
          <thead>
            <tr>
              <th class="tg-fymr">Period</th>
              <th class="tg-fymr">Staff to replace</th>
              <th class="tg-fymr">Class</th>
              <th class="tg-fymr">Room</th>
              <th class="tg-fymr">Assigned staff</th>
            </tr>
          </thead>
          <tbody>
          ${data.periods
            .map((period, index) => {
              return `
            ${period.entries
              .map((entry) => {
                const [request] = entry.coverRequests;
                const requestedBy = formatStaffName(request.requestedBy);
                const assignedTo = request.assignedTo
                  .map(formatStaffName)
                  .join(', ');
                return `<tr>
              <td class="tg-0pky">${period.name}</td>
              <td class="tg-0pky">${requestedBy}</td>
              <td class="tg-0pky">${entry.class.name}</td>
              <td class="tg-0pky">${request.tempRoomCode || entry.roomCode}</td>
              <td class="tg-0pky">${assignedTo}</td>
            </tr>`;
              })
              .join('')}
            ${
              index !== data.periods.length - 1
                ? `<tr>
            <td class="tg-llyw" colspan="5">
              &nbsp;
            </td>
          </tr>`
                : ''
            }
            `;
            })
            .join('')}
          </tbody>
        </table>
      </div>
    </body>
    </html>
  `;

  html2pdf().from(html).set(opt).save();
}

export function exportTableAsPdf(user, rawData = []) {
  const doc = new jsPDF();
  const title = `${formatStaffName(user)} - Absence History`;
  const cols = userAbsenceTableCols.map((col) => col.label);
  const rows = rawData.map((row) =>
    userAbsenceTableCols.map((col) => row[col.id])
  );
  autoTable(doc, {
    head: [cols],
    body: rows,
  });
  doc.save(title);
}

export async function exportChartToPdf(user, elementId, title = '') {
  const html2pdf = await import('html2pdf.js').then((module) => module.default);

  let filename = formatStaffName(user);
  if (title) filename += ` - ${title}`;

  const opt = {
    margin: [1, 0.5],
    filename: `${filename}.pdf`,
    image: { type: 'jpeg', quality: 0.98 },
    html2canvas: { scale: 2 },
    jsPDF: { unit: 'in', format: 'letter', orientation: 'landscape' },
  };

  const element = document.getElementById(elementId);

  if (!element) {
    alert('Error occurred while attempting to export chart');
    return;
  }

  html2pdf()
    .set(opt)
    .from(element)
    .toPdf()
    .get('pdf')
    .then((pdf) => {
      pdf.setFontSize(10);
      pdf.setTextColor(100);
      const totalPages = pdf.internal.getNumberOfPages();
      const pageWidth = pdf.internal.pageSize.width;
      const pageHeight = pdf.internal.pageSize.height;
      for (let i = 1; i <= totalPages; i++) {
        pdf.setPage(i);
        pdf.text(
          `Page ${i} of ${totalPages}`,
          pageWidth / 2,
          pageHeight - 0.5,
          {
            align: 'center',
          }
        );
        pdf.text(filename, pageWidth / 2, 0.5, { align: 'center' });
      }
    })
    .save();
}
