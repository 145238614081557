import { useQuery } from 'react-query';
import ApiError from '../utils/ApiError';

async function fetchUsers() {
  const response = await fetch('/api/users');
  const responseBody = await response.json();
  if (!response.ok) throw new ApiError(responseBody.message, response.status);
  return responseBody;
}

export default function useUsers() {
  return useQuery('users', fetchUsers, {
    refetchOnWindowFocus: false,
  });
}
