import { useSnackbar } from 'notistack';
import { useMutation, useQueryClient } from 'react-query';
import ApiError from '../utils/ApiError';

async function createCoverRequest(values) {
  const response = await fetch('/api/cover', {
    method: 'POST',
    body: JSON.stringify(values),
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const responseBody = await response.json();
  if (!response.ok) throw new ApiError(responseBody.message, response.status);
  return responseBody;
}

export default function useCreateCover() {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(createCoverRequest, {
    onError: (error) =>
      enqueueSnackbar(
        'Failed to create cover request, please try again later.',
        {
          variant: 'error',
        }
      ),
    onSuccess: (data, variables) => {
      const { startDate, endDate } = variables;
      queryClient.invalidateQueries('requests');
      queryClient.invalidateQueries('dashboard');
      queryClient.invalidateQueries('cover-timetable');
      queryClient.invalidateQueries(['timetable', { startDate, endDate }]);
      enqueueSnackbar('Cover request submitted', {
        variant: 'success',
      });
    },
  });
}
