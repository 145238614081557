import { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import useUsers from '../hooks/useUsers';

export default function StaffSelect({
  value,
  onChange,
  onChangeAll,
  required = false,
  selectedUsers = [],
  label = 'Select staff member',
  ...autocompleteProps
}) {
  const [selected, setSelected] = useState(value);
  const { isLoading, data: users, error, isError } = useUsers();

  useEffect(() => {
    setSelected(value);
  }, [value]);

  const handleChange = (_, newValue) => {
    setSelected(newValue);
    onChange(newValue);
  };

  const handleChangeAll = () => selected && onChangeAll(selected);

  return (
    <Autocomplete
      size="small"
      options={
        users?.filter(
          (user) => !selectedUsers.map((user) => user.id).includes(user.id)
        ) || []
      }
      getOptionLabel={(option) => {
        let value = `${option.forename} ${option.surname}`;
        if (option.staffCode) value += ` - ${option.staffCode}`;
        return value;
      }}
      renderInput={(params) => (
        <>
          <TextField
            {...params}
            label={label}
            variant="outlined"
            required={required}
          />
          {!!onChangeAll && (
            <Button style={{ float: 'right' }} onClick={handleChangeAll}>
              Apply to all
            </Button>
          )}
        </>
      )}
      onChange={handleChange}
      value={value}
      loading={isLoading}
      {...autocompleteProps}
    />
  );
}
