import { useState } from 'react';
import { DateRangePicker as DRP } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

export default function DateRangePicker({ startDate, endDate, setState }) {
  const [focusedInput, setFocusedInput] = useState();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <DRP
      required
      startDate={startDate}
      startDateId="start-date"
      endDate={endDate}
      endDateId="end-date"
      onDatesChange={({ startDate, endDate }) =>
        setState((state) => ({ ...state, startDate, endDate }))
      }
      focusedInput={focusedInput}
      onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
      displayFormat={() => 'DD/MM/YYYY'}
      numberOfMonths={mobile ? 1 : 2}
      isDayBlocked={(day) => [0, 6].includes(day.weekday())}
      showClearDates={!!(startDate && endDate)}
      showDefaultInputIcon
      minimumNights={0}
    />
  );
}
