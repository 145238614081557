import { withStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';

const LimitedBackdrop = withStyles({
  root: {
    position: 'absolute',
    zIndex: 9999,
  },
})(Backdrop);

export default LimitedBackdrop;
