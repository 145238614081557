export default function formatStaffName(user, abbreviate = true) {
  if (!user) return;
  let name = `${abbreviate ? user.forename.charAt(0) : user.forename} ${
    user.surname
  }`;
  if (user.staffCode) name += ` (${user.staffCode})`;
  return name;
}

export function formatStaffNameEmail(user, abbreviate = true) {
  let name = `${abbreviate ? user.forename.charAt(0) : user.forename} ${
    user.surname
  }`;
  if (user.staffCode) name += ` (${user.staffCode})`;
  return name + ` - ${user.email}`;
}
